import React from 'react'
import Layout from '../../layout/layout'
import DbSections from '../../components/db/db-sections'
import { Container, Row, Col } from 'reactstrap'

const ServiceEntry = ({ pageContext, transition }) => (
  <Layout contextEntity={pageContext.entity} cssClass="services">
    <section className="summary">
      <Container>
        <Row className="align-items-center">
          <Col lg="6" className="summary-image order-lg-2">
            {pageContext.entity.summaryImage && pageContext.entity.summaryImage.file.url ? (
              <img alt="summary" src={pageContext.entity.summaryImage.file.url} />
            ) : (
              ''
            )}
          </Col>
          <Col lg="6" className="order-lg-1">
            <div className="content">
              <h1 className="section-title">{pageContext.entity.title}</h1>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
    <div id="main-content">
      <section className="section">
        <Container>
          <Row>
            <Col lg="7">
              {pageContext.entity.content && pageContext.entity.content.childMarkdownRemark.html ? (
                <div dangerouslySetInnerHTML={{ __html: pageContext.entity.content.childMarkdownRemark.html }} />
              ) : (
                ''
              )}
            </Col>
          </Row>
        </Container>
      </section>
      {pageContext.entity.sections ? <DbSections>{pageContext.entity.sections}</DbSections> : ''}
    </div>
  </Layout>
)

export default ServiceEntry
